// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import Mailchimp from '../../components/Mailchimp'

import logo from '../../assets/images/logo.png'
import logo_blue from '../../assets/images/logo_blue-bg.png'

import '../../assets/css/all.min.css'
import '../../assets/css/font-awesome.min.css'

export default function Homepage() {
  const [msgName, setMsgName] = useState('')
  const [msgEmail, setMsgEmail] = useState('')
  const [msgPhone, setMsgPhone] = useState('')
  const [msgContent, setMsgContent] = useState(
    'I want to learn about Fastclinic products.'
  )

  const handleToggleHamburger = () => {
    const bar1 = document.getElementById('bar-1')
    const bar2 = document.getElementById('bar-2')
    const bar3 = document.getElementById('bar-3')
    const mobileNav = document.getElementById('mobile-nav')
    bar1.classList.toggle('rotate-clockwise')
    bar2.classList.toggle('remove-second-bar')
    bar3.classList.toggle('rotate-anticlockwise')
    mobileNav.classList.toggle('slide-out')
  }

  const hideMenu = () => {
    const bar1 = document.getElementById('bar-1')
    const bar2 = document.getElementById('bar-2')
    const bar3 = document.getElementById('bar-3')
    const mobileNav = document.getElementById('mobile-nav')
    bar1.classList.remove('rotate-clockwise')
    bar2.classList.remove('remove-second-bar')
    bar3.classList.remove('rotate-anticlockwise')
    mobileNav.classList.remove('slide-out')
  }

  const handleContactFormSubmit = (e) => {
    e.preventDefault()
  }

  return (
    <div>
      {/* Mobile navbar */}
      <div className='mobile-nav' id='mobile-nav'>
        <div className='mobile-nav-container'>
          <div className='nav-container'>
            <ul onClick={handleToggleHamburger}>
              <li>
                <a href='#' className='active'>
                  Home
                </a>
              </li>
              <li>
                <a href='#showcase'>Our Products</a>
              </li>
              <li>
                <a href='#contact'>Contact Us</a>
              </li>
            </ul>
          </div>
          <div className='bottom'>
            <div className='mobile-social-nav-container display-flex align-items-center justify-content-center'>
              <ul>
                <li>
                  <a href='#'>
                    <i className='fa fa-facebook' />
                  </a>
                  <a href='#'>
                    <i className='fa fa-twitter' />
                  </a>
                  <a href='#'>
                    <i className='fa fa-linkedin-in' />
                  </a>
                </li>
              </ul>
            </div>
            <div
              className='credit-container'
              style={{ textAlign: 'center' }}
            >
              <p className='copyright credit-text'>
                Copyright © 2022 Fastclinic Solutions
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* site header */}
      <header className='site-header'>
        <div className='header-container container-md display-flex align-items-center justify-content-between'>
          {/* logo-container */}
          <div className='logo-container'>
            <a href='#index.html' className='logo'>
              <img src={logo} alt='Logo' />
            </a>
          </div>
          {/* menu */}
          <div className='menu-container'>
            <ul className='display-flex align-items-center'>
              <li className='position-relative'>
                <a href='#' className='active'>
                  Home
                </a>
              </li>
              <li className='position-relative' data-dropdown>
                <a href='#showcase' className='display-flex'>
                  Our Products
                </a>
              </li>
              <li className='position-relative' data-dropdown>
                <a href='#contact' className='display-flex'>
                  {' '}
                  Contact Us{' '}
                </a>
              </li>
            </ul>
          </div>
          <div
            className='menu-btn'
            id='menu-btn'
            onClick={handleToggleHamburger}
          >
            <span className='bar' id='bar-1' />
            <span className='bar' id='bar-2' />
            <span className='bar' id='bar-3' />
          </div>
        </div>
      </header>
      {/* Home container */}
      <div
        className='home-container container-lg display-flex justify-content-center'
        onClick={hideMenu}
      >
        <div className='home-content'>
          <h1>
            <span className='secondary-variant'>
              Quality Healthcare for{' '}
            </span>
            <span className='primary-color'>EVERYONE</span>
          </h1>
          <p className='black-color'>
            Advancing healthcare delivery through technological
            innovation.
          </p>
        </div>
      </div>
      {/* app showcase */}
      <div className='app-showcase'>
        <div className='showcase-header'>
          <h2 className='secondary-variant' id='showcase'>
            Our Products
          </h2>
        </div>
        <div className='showcase container-lg display-flex align-items-center flex-direction-column'>
          <div className='showcase-row position-relative display-flex align-items-center justify-content-center'>
            {/* showcase images */}
            <div className='showcase-img-container'>
              <div className='iphone-mockup' />
              <div className='iphone-mockup' />
            </div>
            {/* showcase content */}
            <div className='showcase-content display-flex flex-direction-column justify-content-center'>
              <h1 className='secondary-color'>
                Download the Doorcta app for quick medical
                consultations.
              </h1>
              <p className='secondary-color'>
                Use the Doorcta app for instant and scheduled medical
                consultations with qualified doctors from the comfort
                of your home.
              </p>
              {/* <div class="showcase-cta-buttons"> */}
              <a
                href='https://www.doorcta.com'
                target='_blank'
                rel='noreferrer'
              >
                <button className='cta'>Get the Doorcta App</button>
              </a>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* Newsletter */}
      <section
        className='contact display-flex align-items-center justify-content-center'
        id='contact'
      >
        <div className='contact-container display-flex align-items-center justify-content-center flex-direction-column'>
          <div className='contact-header'>
            <h3 className='secondary-variant'>Contact Us</h3>
            <br />
            <p className='black-color'>
              <strong>Got questions?</strong>
            </p>
            <p classname='black-color'>
              We're excited to hear from you!
            </p>
            <p className='black-color'>Send us a message.</p>
          </div>
          <form
            action='#'
            method='post'
            onSubmit={handleContactFormSubmit}
          >
            <div className='form-boxes'>
              <div className='input-box'>
                <i className='fas fa-user' />
                <input
                  type='text'
                  id='name'
                  name='name'
                  placeholder='Full name'
                  value={msgName}
                  onChange={(e) => setMsgName(e.target.value)}
                />
              </div>
              <div className='input-box'>
                <i className='fas fa-envelope' />
                <input
                  type='email'
                  id='email'
                  name='email'
                  placeholder='Email'
                  value={msgEmail}
                  onChange={(e) => setMsgEmail(e.target.value)}
                  required
                />
              </div>
              <div className='input-box'>
                <i className='fas fa-phone' />
                <input
                  type='tel'
                  id='phone'
                  name='phone'
                  placeholder='Phone number'
                  value={msgPhone}
                  onChange={(e) => setMsgPhone(e.target.value)}
                />
              </div>
              <div className='input-box'>
                <i className='fas fa-pen' />
                <textarea
                  name='message'
                  id='message-box'
                  rows={10}
                  placeholder='Type your message here...'
                  value={msgContent}
                  onChange={(e) => setMsgContent(e.target.value)}
                />
              </div>
            </div>
            <button type='submit' id='contact-submit'>
              Send
            </button>
          </form>
        </div>
      </section>

      {/* Mailchimp Newsletter */}
      <section className='mailchimp-newsletter'>
        <div className='display-flex align-items-center justify-content-center'>
          <Mailchimp />
        </div>
      </section>

      {/* footer */}
      <footer
        className='display-flex flex-direction-column align-items-center justify-content-center'
        id='contact'
      >
        <div className='footer-container display-flex align-items-justify-content-center'>
          <div className='footer-row container-lg'>
            <div className='footer-box'>
              <div className='head'>
                <img
                  style={{ width: 200 }}
                  src={logo_blue}
                  alt='Site Logo.'
                />
              </div>
            </div>
            <div className='footer-box'>
              <div className='head'>
                <h3 className='white-color'>QUICK LINKS</h3>
              </div>
              <ul>
                <li>
                  <a href='#'>Home</a>
                </li>
                <li>
                  <a href='#showcase'>Our Products</a>
                </li>
                <li>
                  <a href='#contact'>Contact Us</a>
                </li>
              </ul>
            </div>
            <div className='footer-box'>
              <div className='head'>
                <h3 className='white-color'>SERVICES</h3>
              </div>
              <ul>
                <li>
                  <a href='#'>Support Center</a>
                </li>
                <li>
                  <a href='#'>Quick Chat</a>
                </li>
                <li>
                  <a href='#'>Terms &amp; Conditions</a>
                </li>
                <li>
                  <a href='#'>Privacy Policy</a>
                </li>
              </ul>
            </div>
            <div className='footer-box'>
              <div className='company-info'>
                <div>
                  <p className='address'>
                    <span>Abuja 901101, NG</span>
                    <span>help@fastclinicsolutions.com</span>
                    <span>+234 (0) 816 872-9113</span>
                  </p>
                </div>
              </div>
              <div className='social-icons'>
                <a
                  href='https://www.facebook.com/profile.php?id=100066780647725'
                  target='_blank'
                  rel='noreferrer'
                  className='social-icon'
                >
                  <i className='fa fa-facebook' />
                </a>
                <a
                  href='https://www.linkedin.com/company/fastclinicsolutions'
                  target='_blank'
                  rel='noreferrer'
                  className='social-icon'
                >
                  <i className='fa fa-linkedin-in' />
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr className='divider' />
        <div className='credit display-flex align-items-center flex-direction-column justify-content-center'>
          <p className='copyright text-center'>
            Copyright © 2023 Fastclinic Solutions
          </p>
        </div>
      </footer>
      {/* back to home */}
      <div className='top-nav-button'>
        <a href='#'>
          <i className='fas fa-arrow-up' />
          <p>Back to top</p>
        </a>
      </div>
    </div>
  )
}

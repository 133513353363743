// @ts-nocheck
import React from 'react'

export default function Mailchimp() {
  return (
    <div id='mc_embed_signup'>
      <form
        action='https://doorcta.us21.list-manage.com/subscribe/post?u=4a0d39a0e5ef6c78dc6c76ab2&id=6fea49eb6d&f_id=001ec5e1f0'
        method='post'
        id='mc-embedded-subscribe-form'
        name='mc-embedded-subscribe-form'
        className='validate'
        target='_blank'
        noValidate
      >
        <div id='mc_embed_signup_scroll'>
          <h2>Subscribe to our newsletter.</h2>
          <div className='indicates-required'>
            <span className='asterisk'>*</span> indicates required
          </div>
          <small>
            Be the first to know about our latest products and
            offerings.
          </small>
          <div className='mc-field-group'>
            <label htmlFor='mce-EMAIL'>
              Email Address <span className='asterisk'>*</span>
            </label>
            <input
              type='email'
              defaultValue=''
              name='EMAIL'
              className='required email'
              id='mce-EMAIL'
              placeholder='yourname@email.com'
              required
            />
            <span id='mce-EMAIL-HELPERTEXT' className='helper_text' />
          </div>
          <div id='mce-responses' className='clear foot'>
            <div
              className='response'
              id='mce-error-response'
              style={{ display: 'none' }}
            />
            <div
              className='response'
              id='mce-success-response'
              style={{ display: 'none' }}
            />
          </div>{' '}
          <div
            style={{ position: 'absolute', left: '-5000px' }}
            aria-hidden='true'
          >
            <input
              type='text'
              name='b_4a0d39a0e5ef6c78dc6c76ab2_6fea49eb6d'
              tabIndex={-1}
              defaultValue=''
            />
          </div>
          <div className='optionalParent'>
            <div className='clear foot'>
              <input
                type='submit'
                defaultValue='Subscribe'
                name='subscribe'
                id='mc-embedded-subscribe'
                className='button'
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

import React from 'react'
import './App.css'

import Homepage from './pages/Homepage'

function App() {
  return <Homepage />
}

export default App
